.Footer {
  text-align: center;
  padding: 10px;

  &__link {
    margin: 10px;

    &:link,
    &:visited {
      color: white;
      text-decoration: none;
    }
  }
}