.Skill {
  display: inline-block;
  padding: .5rem .5rem;
  margin: .5rem;
  max-width: 6rem;
  color: white;
  border-radius: 5px;

  &__wrapper {
    margin-bottom: 15px;
  }

  &__wrapper__header {
    font-size: 1rem;
  }

  &__heading {
    //
  }

  &__content {
    //
  }

  &__body {
    //
  }

  &__name {
    font-size: .9rem;
  }

  &__image {
    padding: .5rem;
    background-color: white;
    height: 4rem;
    width: 4rem;
  }

  &__quantity {
    font-size: .8rem;
  }

  &__category {
    font-size: .7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    line-height: 1.7;
    color: #777;
  }
}