.Project {
  &.card {
    margin-bottom: 15px !important;
  }

  &__header {
    text-transform: capitalize;
  }

  &__body {
    //
  }

  &__title {
    //
  }

  &__text {
    //
  }

  &__tech-stack {
    background-color: inherit !important;
    border-top: none !important;
  }

  &__footer {
    //
  }

  &__link {
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }

  &__tag {
    margin-bottom: .5rem;

    &:not(:last-child) {
      margin-right: .5rem;
    }
  }
}